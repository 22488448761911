<template>
  <v-container id="dashboard" fluid tag="section">
    <v-row>
      <v-col cols="12">
        <base-material-card color="success" inline title="Admin" class="px-5 py-3">
          <v-form>
            <v-container class="py-0" fluid>
              <v-row dense>
                <v-col cols="12" sm="8">
                  <v-text-field
                    v-model="record.name"
                    :rules="inputValidate"
                    :hide-details="hided"
                    :dense="dense"
                    :label="$t('Nume')"
                  />
                </v-col>
                <v-col cols="12" sm="4">
                  <v-select
                    v-model="record.appType"
                    :hide-details="hided"
                    :dense="dense"
                    multiple
                    item-text="name"
                    item-value="value"
                    :label="$t('Tip aplicație')"
                    :items="dictionary.app_type"
                  />
                </v-col>
                <v-col cols="12" md="5">
                  <v-text-field
                    v-model="record.library.public_name"
                    :rules="inputValidate"
                    :hide-details="hided"
                    :dense="dense"
                    :label="$t('Nume public')"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                    v-model="record.library.code"
                    style="width:150px"
                    :rules="inputValidate"
                    :hide-details="hided"
                    :dense="dense"
                    :label="$t('Cod bibliotecă')"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="record.library.link"
                    :hide-details="hided"
                    :dense="dense"
                    :label="$t('Link')"
                  />
                </v-col>
              </v-row>
              <base-material-tabs
                :background-color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-2'"
                color="primary"
                center-active
                centered
                fixed-tabs
              >
                <v-tab>{{ $t("Adresa") }}</v-tab>
                <v-tab>{{ $t("Licență") }}</v-tab>
                <v-tab>{{ $t("Barcode configurari") }}</v-tab>
                <v-tab>{{ $t("Configurări") }}</v-tab>
                <v-tab>{{ $t("Setări circulație") }}</v-tab>
                <v-tab-item class="pt-4">
                  <v-row dense>
                    <v-col cols="12">
                      <v-textarea
                        v-model="record.library.address"
                        :hide-details="hided"
                        :dense="dense"
                        rows="2"
                        :label="$t('Adresa')"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-autocomplete
                        v-model="record.library.county"
                        style="width:250px"
                        :hide-details="hided"
                        :dense="dense"
                        item-text="name"
                        item-value="value"
                        :label="$t('Județ')"
                        :items="dictionary.JudeteRomania"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-autocomplete
                        v-model="record.library.country"
                        style="width:250px"
                        :hide-details="hided"
                        :dense="dense"
                        item-text="name"
                        item-value="value"
                        :label="$t('Țara')"
                        :items="dictionary.countriesAlpha2"
                      />
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item class="pt-4">
                  <v-row dense>
                    <v-col cols="12" sm="4">
                      <v-text-field
                        v-model="record.config.license.owner"
                        :hide-details="hided"
                        :dense="dense"
                        :label="$t('Deținător')"
                      />
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field
                        v-model="record.config.license.app_token"
                        :hide-details="hided"
                        :dense="dense"
                        :label="$t('Token')"
                      />
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field
                        v-model="record.config.license.type"
                        :hide-details="hided"
                        :dense="dense"
                        :label="$t('Tip')"
                      />
                    </v-col>
                  </v-row>
                  <v-subheader class="green--text">{{ $t("Limite") }}</v-subheader>
                  <v-row dense>
                    <v-col cols="12" sm="4">
                      <v-text-field
                        v-model="record.config.license.limits.user_limit"
                        style="width:200px"
                        :hide-details="hided"
                        :dense="dense"
                        type="number"
                        :label="$t('Bibliotecari')"
                      />
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field
                        v-model="record.config.license.limits.books"
                        style="width:200px"
                        :hide-details="hided"
                        :dense="dense"
                        type="number"
                        :label="$t('Cărți')"
                      />
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field
                        v-model="record.config.license.limits.clients"
                        style="width:200px"
                        :hide-details="hided"
                        :dense="dense"
                        type="number"
                        :label="$t('Cititori')"
                      />
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field
                        v-model="record.config.license.limits.digital"
                        style="width:200px"
                        :hide-details="hided"
                        :dense="dense"
                        type="number"
                        :label="$t('Resurse media')"
                      />
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field
                        v-model="record.config.license.limits.storage"
                        style="width:200px"
                        :hide-details="hided"
                        :dense="dense"
                        type="number"
                        :label="$t('Spațiu(MB)')"
                      />
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item class="pt-4">
                  <v-subheader>{{ $t("Barcode cititor") }}</v-subheader>
                  <v-row dense>
                    <v-col cols="12" sm="4">
                      <v-text-field
                        v-model="record.config.library.barcodeReader.length"
                        style="width:200px"
                        :hide-details="hided"
                        :dense="dense"
                        type="number"
                        :label="$t('Lungime')"
                      />
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field
                        v-model="record.config.library.barcodeReader.prefix"
                        style="width:200px"
                        :hide-details="hided"
                        :dense="dense"
                        :label="$t('Prefix')"
                      />
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field
                        v-model="record.config.library.barcodeReader.sufix"
                        style="width:200px"
                        :hide-details="hided"
                        :dense="dense"
                        :label="$t('Sufix')"
                      />
                    </v-col>
                  </v-row>
                  <v-subheader>{{ $t("Barcode inventar") }}</v-subheader>
                  <v-row dense>
                    <v-col cols="12" sm="4">
                      <v-text-field
                        v-model="record.config.library.barcodeInventory.length"
                        style="width:200px"
                        :hide-details="hided"
                        :dense="dense"
                        type="number"
                        :label="$t('Lungime')"
                      />
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field
                        v-model="record.config.library.barcodeInventory.prefix"
                        style="width:200px"
                        :hide-details="hided"
                        :dense="dense"
                        :label="$t('Prefix')"
                      />
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field
                        v-model="record.config.library.barcodeInventory.sufix"
                        style="width:200px"
                        :hide-details="hided"
                        :dense="dense"
                        :label="$t('Sufix')"
                      />
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item class="pt-4">
                  <v-row dense>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="record.config.library.client_subscription.signup_duration_months"
                        style="width:200px"
                        type="number"
                        :hide-details="hided"
                        :dense="dense"
                        suffix="luni"
                        :label="$t('Valabilitate înscriere')"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="record.config.library.client_subscription.visa_duration_months"
                        style="width:200px"
                        type="number"
                        :hide-details="hided"
                        :dense="dense"
                        suffix="luni"
                        :label="$t('Valabilitate vizare')"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-combobox
                        v-model="record.config.library.regimes"
                        :hide-details="hided"
                        :dense="dense"
                        chips
                        deletable-chips
                        multiple
                        :label="$t('Regimuri')"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-combobox
                        v-model="record.config.library.sites"
                        :hide-details="hided"
                        :dense="dense"
                        chips
                        deletable-chips
                        multiple
                        :label="$t('Gestiuni')"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-combobox
                        v-model="record.config.library.clients_types"
                        :hide-details="hided"
                        :dense="dense"
                        chips
                        deletable-chips
                        multiple
                        :label="$t('Clase cititori')"
                      />
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item class="pt-4">
                  <!-- <v-col cols="12">
                      <div v-for="(gestiuni, gestiune) in config.library.loan" :key="'ges' + gestiune">
                        <div class="d-flex">
                          <v-icon>mdi-menu-down</v-icon>
                          <v-hover v-slot:default="{ hover }">
                            <v-text-field :value="gestiune" style="width:200px" prefix="Gestiune: " dense hide-details>
                              <template v-slot:append-outer>
                                <div v-show="hover" :class="{ 'd-inline-flex': hover }">
                                  <v-icon tabindex="-1" @click.stop.prevent="">
                                    mdi-plus
                                  </v-icon>
                                  <v-icon tabindex="-1" @click.stop.prevent="">
                                    mdi-minus
                                  </v-icon>
                                </div>
                              </template>
                            </v-text-field>
                          </v-hover>
                        </div>
                        <div v-for="(utilizatori, utilizator) in gestiuni" :key="'uti' + utilizator" class="ml-6">
                          {{ utilizator }}
                        </div>
                      </div>
                    </v-col> -->
                  <v-subheader>{{ $t("Excepții împrumuturi") }}</v-subheader>
                  <v-row dense>
                    <v-col cols="12" md="6">
                      <v-autocomplete
                        v-model="record.config.library.loan_exceptions.restricted_sites_for_loan"
                        :hide-details="hided"
                        :dense="dense"
                        chips
                        deletable-chips
                        multiple
                        :items="record.config.library.sites"
                        :label="$t('Gestiune')"
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-autocomplete
                        v-model="record.config.library.loan_exceptions.restricted_regimes_for_loan"
                        :hide-details="hided"
                        :dense="dense"
                        chips
                        deletable-chips
                        multiple
                        :items="record.config.library.regimes"
                        :label="$t('Regim')"
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-autocomplete
                        v-model="record.config.library.loan_exceptions.restricted_inv_categories"
                        :hide-details="hided"
                        :dense="dense"
                        chips
                        deletable-chips
                        multiple
                        item-text="name"
                        item-value="value"
                        :items="dictionary.categorii_inv"
                        :label="$t('Categorie inventar')"
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-autocomplete
                        v-model="record.config.library.loan_exceptions.restricted_cln_categories"
                        :hide-details="hided"
                        :dense="dense"
                        chips
                        deletable-chips
                        multiple
                        item-text="name"
                        item-value="value"
                        :items="dictionary.categorii_cln"
                        :label="$t('Categorie cititor')"
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-autocomplete
                        v-model="record.config.library.loan_exceptions.restricted_cln_passport_status"
                        :hide-details="hided"
                        :dense="dense"
                        chips
                        deletable-chips
                        multiple
                        item-text="name"
                        item-value="value"
                        :items="dictionary.clnStarePermis"
                        :label="$t('Stare permis')"
                      />
                    </v-col>
                  </v-row>
                  <v-subheader>{{ $t("Excepții rezervări") }}</v-subheader>
                  <v-row dense>
                    <v-col cols="12" md="6">
                      <v-autocomplete
                        v-model="record.config.library.reservation_exceptions.restricted_sites"
                        :hide-details="hided"
                        :dense="dense"
                        chips
                        deletable-chips
                        multiple
                        :items="record.config.library.sites"
                        :label="$t('Gestiune')"
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-autocomplete
                        v-model="record.config.library.reservation_exceptions.restricted_regimes"
                        :hide-details="hided"
                        :dense="dense"
                        chips
                        deletable-chips
                        multiple
                        :items="record.config.library.regimes"
                        :label="$t('Regim')"
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-autocomplete
                        v-model="record.config.library.reservation_exceptions.restricted_inv_categories"
                        :hide-details="hided"
                        :dense="dense"
                        chips
                        deletable-chips
                        multiple
                        item-text="name"
                        item-value="value"
                        :items="dictionary.categorii_inv"
                        :label="$t('Categorie inventar')"
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-autocomplete
                        v-model="record.config.library.reservation_exceptions.restricted_cln_categories"
                        :hide-details="hided"
                        :dense="dense"
                        chips
                        deletable-chips
                        multiple
                        item-text="name"
                        item-value="value"
                        :items="dictionary.categorii_cln"
                        :label="$t('Categorie cititor')"
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-autocomplete
                        v-model="record.config.library.reservation_exceptions.restricted_cln_passport_status"
                        :hide-details="hided"
                        :dense="dense"
                        chips
                        deletable-chips
                        multiple
                        item-text="name"
                        item-value="value"
                        :items="dictionary.clnStarePermis"
                        :label="$t('Stare permis')"
                      />
                    </v-col>
                  </v-row>
                  <v-subheader>{{ $t("Limite împrumut") }}</v-subheader>
                  <v-row>
                    <v-col cols="12" md="6">
                      <span class="d-none">{{ render }}</span>
                      <v-treeview :items="bibLoan" expand-icon="mdi-chevron-down">
                        <template v-slot:label="{ item }">
                          <v-hover v-if="item.val == 'gestiune'" v-slot:default="{ hover }">
                            <div class="d-flex">
                              <span class="mt-1 mr-2 grey--text">Gestiune:</span>
                              <v-text-field
                                :value="item.name"
                                class="mt-0"
                                dense
                                hide-details
                                @change="chgsetGestiune(item.name, $event)"
                              >
                                <template v-slot:append-outer>
                                  <div v-show="hover" :class="{ 'd-inline-flex': hover }">
                                    <v-icon tabindex="-1" @click.stop.prevent="addsetGestiune(item)">
                                      mdi-plus
                                    </v-icon>
                                    <v-icon tabindex="-1" @click.stop.prevent="delsetGestiune(item)">
                                      mdi-minus
                                    </v-icon>
                                  </div>
                                </template>
                              </v-text-field>
                            </div>
                          </v-hover>
                          <v-hover v-else-if="item.val == 'tip'" v-slot:default="{ hover }">
                            <div class="d-flex">
                              <span class="mt-1 mr-2 grey--text">Tip utilizator:</span>
                              <v-combobox
                                :value="item.name"
                                class="mt-0"
                                append-icon=""
                                dense
                                hide-details
                                :items="record.config.library.clients_types"
                                @change="chgsetTipUtilizator(item, item.name, $event)"
                              >
                                <template v-slot:append-outer>
                                  <div v-show="hover" :class="{ 'd-inline-flex': hover }">
                                    <v-icon tabindex="-1" @click.stop.prevent="addsetTipUtilizator(item)">
                                      mdi-plus
                                    </v-icon>
                                    <v-icon tabindex="-1" @click.stop.prevent="delsetTipUtilizator(item)">
                                      mdi-minus
                                    </v-icon>
                                  </div>
                                </template>
                              </v-combobox>
                            </div>
                          </v-hover>
                          <v-hover v-else-if="item.val == 'regim'" v-slot:default="{ hover }">
                            <div class="d-flex">
                              <span class="mt-1 mr-2 grey--text">Regim:</span>
                              <v-combobox
                                :value="item.name"
                                append-icon=""
                                class="mt-0"
                                dense
                                hide-details
                                :items="record.config.library.regimes"
                                @change="chgsetRegim(item, item.name, $event)"
                              >
                                <template v-slot:append-outer>
                                  <div v-show="hover" :class="{ 'd-inline-flex': hover }">
                                    <v-icon tabindex="-1" @click.stop.prevent="addsetRegim(item)">
                                      mdi-plus
                                    </v-icon>
                                    <v-icon tabindex="-1" @click.stop.prevent="delsetRegim(item)">
                                      mdi-minus
                                    </v-icon>
                                  </div>
                                </template>
                              </v-combobox>
                            </div>
                          </v-hover>
                          <div v-else-if="item.val == 'days'" class="d-flex">
                            <span class="mt-1 mr-2 grey--text">Nr. zile:</span>
                            <v-text-field
                              :value="item.name"
                              class="mt-0"
                              dense
                              hide-details
                              type="number"
                              @change="chgsetDays(item, item.name, $event)"
                            />
                          </div>
                          <div v-else-if="item.val == 'items'" class="d-flex">
                            <span class="mt-1 mr-2 grey--text">Nr. exemplare:</span>
                            <v-text-field
                              :value="item.name"
                              class="mt-0"
                              dense
                              hide-details
                              type="number"
                              @change="chgsetItems(item, item.name, $event)"
                            />
                          </div>
                        </template>
                      </v-treeview>
                    </v-col>
                  </v-row>
                </v-tab-item>
              </base-material-tabs>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
    <search-link ref="searchLink" select-event @select="setLinkValue" />
    <create-edit ref="createEdit" />
  </v-container>
</template>

<script>
import SearchLink from "../../../components/containers/SearchLink"
import CreateEdit from "../../../views/settings/AddDictionaryEdit"

// import SDateTime from "../../../components/inputs/DateTime"
// import SAutocomplete from "../../../components/inputs/Autocomplete"

import { EventBus } from "../../../EventBus"
import axios from "../../../plugins/axios"
//import axio from "axios"
export default {
  name: "DashboardDashboard",
  components: {
    SearchLink,
    CreateEdit
    // SDateTime,
    // SAutocomplete
  },
  data() {
    return {
      dense: true,
      hided: false,
      fileName: "",
      file: {},
      urlImg: null,
      searchId: "",
      dictionary: {},
      linkArray: null,
      linkField: null,
      linkSubfield: null,
      linkIndex: null,
      selectId: null,
      render: 1,
      config: {
        library: {
          barcodeReader: {
            length: 5,
            prefix: "HD"
          },
          barcodeInventory: {
            length: 6
          },
          loan: {
            "1 - General": {
              General: {
                Împrumut: {
                  days: 21,
                  items: 3
                },
                "Împrumut la sala de lectură": {
                  days: 1,
                  items: 3
                },
                Rezervare: {
                  days: 1,
                  items: 3
                }
              },
              Elevi: {
                Împrumut: {
                  days: 21,
                  items: 3
                },
                "Împrumut la sala de lectură": {
                  days: 1,
                  items: 3
                },
                Rezervare: {
                  days: 1,
                  items: 3
                }
              },
              Profesori: {
                Împrumut: {
                  days: 21,
                  items: 3
                },
                "Împrumut la sala de lectură": {
                  days: 1,
                  items: 3
                },
                Rezervare: {
                  days: 1,
                  items: 3
                },
                "Circuit intern": {
                  days: 100,
                  items: 3
                }
              },
              Pensionar: {
                Împrumut: {
                  days: 21,
                  items: 3
                },
                "Împrumut la sala de lectură": {
                  days: 1,
                  items: 3
                },
                Rezervare: {
                  days: 1,
                  items: 3
                }
              },
              "Cititor Sala Lectura": {
                Împrumut: {
                  days: 21,
                  items: 3
                },
                "Împrumut la sala de lectură": {
                  days: 1,
                  items: 3
                },
                Rezervare: {
                  days: 1,
                  items: 3
                }
              },
              Bibliotecar: {
                Împrumut: {
                  days: 100,
                  items: 3
                },
                "Împrumut la sala de lectură": {
                  days: 3,
                  items: 3
                },
                Rezervare: {
                  days: 1,
                  items: 3
                },
                "Circuit intern": {
                  days: 100,
                  items: 3
                }
              },
              "Colaborator Biblioteca": {
                Împrumut: {
                  days: 21,
                  items: 3
                },
                "Împrumut la sala de lectură": {
                  days: 21,
                  items: 3
                },
                Rezervare: {
                  days: 1,
                  items: 3
                },
                "Circuit intern": {
                  days: 1,
                  items: 3
                }
              },
              Special: {
                Împrumut: {
                  days: 21,
                  items: 3
                },
                "Împrumut la sala de lectură": {
                  days: 1,
                  items: 3
                },
                Rezervare: {
                  days: 1,
                  items: 3
                }
              },
              "Uz Intern": {
                Împrumut: {
                  days: 21,
                  items: 3
                },
                "Împrumut la sala de lectură": {
                  days: 21,
                  items: 3
                },
                Rezervare: {
                  days: 1,
                  items: 3
                },
                "Circuit intern": {
                  days: 1,
                  items: 3
                }
              }
            },
            "2 - Depozit legal": {
              General: {
                Împrumut: {
                  days: 21,
                  items: 3
                },
                "Împrumut la sala de lectură": {
                  days: 21,
                  items: 3
                },
                Rezervare: {
                  days: 1,
                  items: 3
                }
              },
              Elevi: {
                Împrumut: {
                  days: 21,
                  items: 3
                },
                "Împrumut la sala de lectură": {
                  days: 1,
                  items: 3
                },
                Rezervare: {
                  days: 1,
                  items: 3
                }
              },
              Profesori: {
                Împrumut: {
                  days: 21,
                  items: 3
                },
                "Împrumut la sala de lectură": {
                  days: 1,
                  items: 3
                },
                Rezervare: {
                  days: 1,
                  items: 3
                },
                "Circuit intern": {
                  days: 100,
                  items: 3
                }
              },
              "Cititor Sala Lectura": {
                Împrumut: {
                  days: 21,
                  items: 3
                },
                "Împrumut la sala de lectură": {
                  days: 21,
                  items: 3
                },
                Rezervare: {
                  days: 1,
                  items: 3
                }
              },
              Bibliotecar: {
                Împrumut: {
                  days: 21,
                  items: 3
                },
                "Împrumut la sala de lectură": {
                  days: 21,
                  items: 3
                },
                Rezervare: {
                  days: 1,
                  items: 3
                },
                "Circuit intern": {
                  days: 1,
                  items: 3
                }
              },
              "Colaborator Biblioteca": {
                Împrumut: {
                  days: 21,
                  items: 3
                },
                "Împrumut la sala de lectură": {
                  days: 21,
                  items: 3
                },
                Rezervare: {
                  days: 1,
                  items: 3
                },
                "Circuit intern": {
                  days: 1,
                  items: 3
                }
              },
              Pensionar: {
                Împrumut: {
                  days: 21,
                  items: 3
                },
                "Împrumut la sala de lectură": {
                  days: 21,
                  items: 3
                },
                Rezervare: {
                  days: 1,
                  items: 3
                }
              },
              Special: {
                Împrumut: {
                  days: 21,
                  items: 3
                },
                "Împrumut la sala de lectură": {
                  days: 21,
                  items: 3
                },
                Rezervare: {
                  days: 1,
                  items: 3
                }
              },
              "Uz Intern": {
                Împrumut: {
                  days: 21,
                  items: 3
                },
                "Împrumut la sala de lectură": {
                  days: 21,
                  items: 3
                },
                Rezervare: {
                  days: 1,
                  items: 3
                },
                "Circuit intern": {
                  days: 1,
                  items: 3
                }
              }
            }
          },
          loan_exceptions: {
            restricted_sites_for_loan: ["Scoasa din inventar", "Depozit legal"],
            restricted_regimes_for_loan: ["Scoasa din inventar"],
            restricted_inv_categories: ["Casat", "Pentru casare", "În prelucrare"],
            restricted_cln_categories: ["Inactiv"],
            restricted_cln_passport_status: ["Expirat", "Anulat", "Suspendat"]
          },
          reservation_exceptions: {
            restricted_sites: ["Scoasa din inventar", "Depozit legal"],
            restricted_regimes: ["Scoasa din inventar"],
            restricted_inv_categories: ["Casat", "Pentru casare", "În prelucrare"],
            restricted_cln_categories: ["Inactiv"],
            restricted_cln_passport_status: ["Expirat", "Anulat", "Suspendat"]
          },
          client_subscription: {
            signup_duration_months: 60,
            visa_duration_months: 12
          }
        },
        application: {
          settings: {
            app: {
              sessionLifetime: 28800,
              smsApiKey: "https://api.smssphere.com/v2.1/webapp/sms"
            }
          }
        },
        license: {
          owner: "Colegiul Național Decebal, Deva",
          app_token: "nDKmF39sGtUadW6T",
          type: "general",
          limits: {
            user_limit: 20,
            books: 10000,
            inventories: 50000,
            clients: 50000,
            digital: 5000,
            storage: 10240
          },
          modules: {
            catalog_public: true,
            catalogare: true,
            circulatie: true,
            rapoarte: true,
            digital: false,
            opac: true,
            chat: false,
            import: true,
            social_media: true,
            seo: true,
            udc_explorer: true,
            special_tools: true,
            email_notifications: true,
            sms_notifications: true,
            advanced_editing: true,
            cms_management: false,
            online_courses: true,
            publicevents_manager: true,
            marketing_campaigns: false,
            ads_manager: true,
            bibliography_manager: true,
            school_marks: true,
            document_templating: false,
            database_manager: true
          }
        }
      },
      record: {
        name: "",
        appType: [""],
        library: { public_name: "", code: "", address: "", county: "", country: "" },
        config: {
          application: { settings: { app: { sessionLifetime: "", smsApiKey: "" } } },
          license: {
            owner: "",
            app_token: "",
            type: "",
            limits: { user_limit: "", books: "", inventories: "", clients: "", digital: "", storage: "" },
            modules: {
              catalog_public: false,
              catalogare: false,
              circulatie: false,
              rapoarte: false,
              digital: false,
              opac: false,
              chat: false,
              import: false,
              social_media: false,
              seo: false,
              udc_explorer: false,
              special_tools: false,
              email_notifications: false,
              sms_notifications: false,
              advanced_editing: false,
              cms_management: false,
              online_courses: false,
              publicevents_manager: false,
              marketing_campaigns: false,
              ads_manager: false,
              bibliography_manager: false,
              school_marks: false,
              document_templating: false,
              database_manager: false
            }
          },
          library: {
            barcodeReader: { length: "", prefix: "", sufix: "" },
            barcodeInventory: { length: "", prefix: "", sufix: "" },
            regimes: [],
            sites: [],
            clients_types: [],
            loan: {
              "1 - General": {
                General: {
                  Împrumut: {
                    days: 21,
                    items: 3
                  },
                  "Împrumut la sala de lectură": {
                    days: 1,
                    items: 3
                  },
                  Rezervare: {
                    days: 1,
                    items: 3
                  }
                },
                Elevi: {
                  Împrumut: {
                    days: 21,
                    items: 3
                  },
                  "Împrumut la sala de lectură": {
                    days: 1,
                    items: 3
                  },
                  Rezervare: {
                    days: 1,
                    items: 3
                  }
                },
                Profesori: {
                  Împrumut: {
                    days: 21,
                    items: 3
                  },
                  "Împrumut la sala de lectură": {
                    days: 1,
                    items: 3
                  },
                  Rezervare: {
                    days: 1,
                    items: 3
                  },
                  "Circuit intern": {
                    days: 100,
                    items: 3
                  }
                },
                Pensionar: {
                  Împrumut: {
                    days: 21,
                    items: 3
                  },
                  "Împrumut la sala de lectură": {
                    days: 1,
                    items: 3
                  },
                  Rezervare: {
                    days: 1,
                    items: 3
                  }
                },
                "Cititor Sala Lectura": {
                  Împrumut: {
                    days: 21,
                    items: 3
                  },
                  "Împrumut la sala de lectură": {
                    days: 1,
                    items: 3
                  },
                  Rezervare: {
                    days: 1,
                    items: 3
                  }
                },
                Bibliotecar: {
                  Împrumut: {
                    days: 100,
                    items: 3
                  },
                  "Împrumut la sala de lectură": {
                    days: 3,
                    items: 3
                  },
                  Rezervare: {
                    days: 1,
                    items: 3
                  },
                  "Circuit intern": {
                    days: 100,
                    items: 3
                  }
                },
                "Colaborator Biblioteca": {
                  Împrumut: {
                    days: 21,
                    items: 3
                  },
                  "Împrumut la sala de lectură": {
                    days: 21,
                    items: 3
                  },
                  Rezervare: {
                    days: 1,
                    items: 3
                  },
                  "Circuit intern": {
                    days: 1,
                    items: 3
                  }
                },
                Special: {
                  Împrumut: {
                    days: 21,
                    items: 3
                  },
                  "Împrumut la sala de lectură": {
                    days: 1,
                    items: 3
                  },
                  Rezervare: {
                    days: 1,
                    items: 3
                  }
                },
                "Uz Intern": {
                  Împrumut: {
                    days: 21,
                    items: 3
                  },
                  "Împrumut la sala de lectură": {
                    days: 21,
                    items: 3
                  },
                  Rezervare: {
                    days: 1,
                    items: 3
                  },
                  "Circuit intern": {
                    days: 1,
                    items: 3
                  }
                }
              },
              "2 - Depozit legal": {
                General: {
                  Împrumut: {
                    days: 21,
                    items: 3
                  },
                  "Împrumut la sala de lectură": {
                    days: 21,
                    items: 3
                  },
                  Rezervare: {
                    days: 1,
                    items: 3
                  }
                },
                Elevi: {
                  Împrumut: {
                    days: 21,
                    items: 3
                  },
                  "Împrumut la sala de lectură": {
                    days: 1,
                    items: 3
                  },
                  Rezervare: {
                    days: 1,
                    items: 3
                  }
                },
                Profesori: {
                  Împrumut: {
                    days: 21,
                    items: 3
                  },
                  "Împrumut la sala de lectură": {
                    days: 1,
                    items: 3
                  },
                  Rezervare: {
                    days: 1,
                    items: 3
                  },
                  "Circuit intern": {
                    days: 100,
                    items: 3
                  }
                },
                "Cititor Sala Lectura": {
                  Împrumut: {
                    days: 21,
                    items: 3
                  },
                  "Împrumut la sala de lectură": {
                    days: 21,
                    items: 3
                  },
                  Rezervare: {
                    days: 1,
                    items: 3
                  }
                },
                Bibliotecar: {
                  Împrumut: {
                    days: 21,
                    items: 3
                  },
                  "Împrumut la sala de lectură": {
                    days: 21,
                    items: 3
                  },
                  Rezervare: {
                    days: 1,
                    items: 3
                  },
                  "Circuit intern": {
                    days: 1,
                    items: 3
                  }
                },
                "Colaborator Biblioteca": {
                  Împrumut: {
                    days: 21,
                    items: 3
                  },
                  "Împrumut la sala de lectură": {
                    days: 21,
                    items: 3
                  },
                  Rezervare: {
                    days: 1,
                    items: 3
                  },
                  "Circuit intern": {
                    days: 1,
                    items: 3
                  }
                },
                Pensionar: {
                  Împrumut: {
                    days: 21,
                    items: 3
                  },
                  "Împrumut la sala de lectură": {
                    days: 21,
                    items: 3
                  },
                  Rezervare: {
                    days: 1,
                    items: 3
                  }
                },
                Special: {
                  Împrumut: {
                    days: 21,
                    items: 3
                  },
                  "Împrumut la sala de lectură": {
                    days: 21,
                    items: 3
                  },
                  Rezervare: {
                    days: 1,
                    items: 3
                  }
                },
                "Uz Intern": {
                  Împrumut: {
                    days: 21,
                    items: 3
                  },
                  "Împrumut la sala de lectură": {
                    days: 21,
                    items: 3
                  },
                  Rezervare: {
                    days: 1,
                    items: 3
                  },
                  "Circuit intern": {
                    days: 1,
                    items: 3
                  }
                }
              }
            },
            loan_exceptions: {
              restricted_sites_for_loan: [""],
              restricted_regimes_for_loan: [""],
              restricted_inv_categories: [""],
              restricted_cln_categories: [""],
              restricted_cln_passport_status: [""]
            },
            reservation_exceptions: {
              restricted_sites: [""],
              restricted_regimes: [""],
              restricted_inv_categories: [""],
              restricted_cln_categories: [""],
              restricted_cln_passport_status: [""]
            },
            client_subscription: {
              signup_duration_months: 60,
              visa_duration_months: 12
            }
          }
        }
      },
      recordView: {
        name: "",
        appType: [""],
        library: { public_name: "", code: "", address: "", county: "", country: "" },
        config: {
          application: { settings: { app: { sessionLifetime: "", smsApiKey: "" } } },
          license: {
            owner: "",
            app_token: "",
            type: "",
            limits: { user_limit: "", books: "", inventories: "", clients: "", digital: "", storage: "" },
            modules: {
              catalog_public: false,
              catalogare: false,
              circulatie: false,
              rapoarte: false,
              digital: false,
              opac: false,
              chat: false,
              import: false,
              social_media: false,
              seo: false,
              udc_explorer: false,
              special_tools: false,
              email_notifications: false,
              sms_notifications: false,
              advanced_editing: false,
              cms_management: false,
              online_courses: false,
              publicevents_manager: false,
              marketing_campaigns: false,
              ads_manager: false,
              bibliography_manager: false,
              school_marks: false,
              document_templating: false,
              database_manager: false
            }
          },
          library: {
            barcodeReader: { length: "", prefix: "", sufix: "" },
            barcodeInventory: { length: "", prefix: "", sufix: "" },
            loan: {},
            loan_exceptions: {
              restricted_sites_for_loan: [""],
              restricted_regimes_for_loan: [""],
              restricted_inv_categories: [""],
              restricted_cln_categories: [""],
              restricted_cln_passport_status: [""]
            },
            reservation_exceptions: {
              restricted_sites: [""],
              restricted_regimes: [""],
              restricted_inv_categories: [""],
              restricted_cln_categories: [""],
              restricted_cln_passport_status: [""]
            },
            client_subscription: {
              signup_duration_months: 60,
              visa_duration_months: 12
            }
          }
        }
      },
      schema: {
        dictionary: [
          "system.app_type",
          "all.JudeteRomania",
          "all.countriesAlpha2",
          "all.clnStarePermis",
          "all.categorii_inv",
          "all.categorii_cln"
        ]
      },
      rows: []
    }
  },

  computed: {
    settingsTipUtilizator() {
      const items = []
      for (const gestiune in this.record.config.library.loan) {
        for (const tipUtilizator in this.record.config.library.loan[gestiune]) {
          if (items.indexOf(tipUtilizator) < 0) {
            items.push(tipUtilizator)
          }
        }
      }
      return items
    },
    settingsRegim() {
      const items = []
      for (const gestiune in this.record.config.library.loan) {
        for (const tipUtilizator in this.record.config.library.loan[gestiune]) {
          for (const regim in this.record.config.library.loan[gestiune][tipUtilizator]) {
            if (items.indexOf(regim) < 0) {
              items.push(regim)
            }
          }
        }
      }
      return items
    },
    settingsGestiuni() {
      const items = []
      for (const gestiune in this.record.config.library.loan) {
        items.push(gestiune)
      }
      return items
    },
    bibLoan() {
      this.$log("bibLoan ", this.record.config.library.loan)
      const items = []
      let i = 1
      for (const gestiune in this.record.config.library.loan) {
        if (this.record.config.library.loan[gestiune]) {
          const ges = { id: i++, val: "gestiune", name: gestiune, children: [] }
          for (const tipUtilizator in this.record.config.library.loan[gestiune]) {
            if (this.record.config.library.loan[gestiune][tipUtilizator]) {
              const tip = { id: i++, val: "tip", gestiune, name: tipUtilizator, children: [] }
              for (const regim in this.record.config.library.loan[gestiune][tipUtilizator]) {
                if (this.record.config.library.loan[gestiune][tipUtilizator][regim]) {
                  const reg = {
                    id: i++,
                    val: "regim",
                    name: regim,
                    gestiune,
                    tipUtilizator,
                    children: [
                      {
                        id: i++,
                        val: "days",
                        gestiune,
                        tipUtilizator,
                        regim,
                        name: this.record.config.library.loan[gestiune][tipUtilizator][regim].days
                      },
                      {
                        id: i++,
                        val: "items",
                        gestiune,
                        tipUtilizator,
                        regim,
                        name: this.record.config.library.loan[gestiune][tipUtilizator][regim].items
                      }
                    ]
                  }
                  tip.children.push(reg)
                }
              }
              ges.children.push(tip)
            }
          }
          items.push(ges)
        }
      }
      return items
    },
    inputValidate() {
      const rule = []
      rule.push(v => !!v || this.$t("required"))
      return rule
    },
    companyId() {
      return this.$store.getters.companyId
    }
  },
  created() {
    if (this.schema.dictionary) {
      this.schema.dictionary.map(itm => this.getDictionaryValues(itm))
    }
    const country = []
    this.rows.map(itm => {
      country.push({ name: itm.value, value: itm.value })
    })
    EventBus.$on("dictionary-reload", dict => {
      this.$log("dictionary-reload ", dict)
      if (this.selectId == dict.id) {
        this.getDictionaryValues("all." + dict.dictionary)
        if (dict.select) {
          this.setLinkValue(dict.select)
        }
      }
    })
    // EventBus.$on("table-form-select", value => {
    //   this.$log("selected ", value)
    // })
    //this.$log(JSON.stringify(country))
    // axios.post("anaf_check_tva", [{ cui: 18158683, data: "2020-04-30" }]).then(response => {
    //   this.$log("response ", response)
    // })
  },
  methods: {
    addsetGestiune(itm) {
      const rec = {}
      itm.children.map(tip => {
        rec[tip.name] = {}
        tip.children.map(regim => {
          rec[tip.name][regim.name] = { days: regim.children[0].name, items: regim.children[1].name }
        })
      })
      this.$set(this.record.config.library.loan, "Gestiune noua", rec)
      this.render++
    },
    delsetGestiune(itm) {
      this.$set(this.record.config.library.loan, itm.name, undefined)
      this.render++
    },
    chgsetGestiune(oldv, newv) {
      this.$set(this.record.config.library.loan, newv, this.record.config.library.loan[oldv])
      this.$set(this.record.config.library.loan, oldv, undefined)
      this.render++
    },
    addsetTipUtilizator(itm) {
      const rec = {}
      itm.children.map(regim => {
        rec[regim.name] = { days: regim.children[0].name, items: regim.children[1].name }
      })
      this.$set(this.record.config.library.loan[itm.gestiune], "Tip utilizator nou", rec)
      this.render++
    },
    delsetTipUtilizator(itm) {
      this.$set(this.record.config.library.loan[itm.gestiune], itm.name, undefined)
      this.render++
    },
    chgsetTipUtilizator(item, oldv, newv) {
      this.$set(
        this.record.config.library.loan[item.gestiune],
        newv,
        this.record.config.library.loan[item.gestiune][oldv]
      )
      this.$set(this.record.config.library.loan[item.gestiune], oldv, undefined)
      this.render++
    },
    addsetRegim(itm) {
      const rec = { days: itm.children[0].name, items: itm.children[1].name }
      this.$set(this.record.config.library.loan[itm.gestiune][itm.tipUtilizator], "Regim nou", rec)
      this.render++
    },
    delsetRegim(itm) {
      this.$set(this.record.config.library.loan[itm.gestiune][itm.tipUtilizator], itm.name, undefined)
      this.render++
    },
    chgsetRegim(item, oldv, newv) {
      this.$set(
        this.record.config.library.loan[item.gestiune][item.tipUtilizator],
        newv,
        this.record.config.library.loan[item.gestiune][item.tipUtilizator][oldv]
      )
      this.$set(this.record.config.library.loan[item.gestiune][item.tipUtilizator], oldv, undefined)
      this.render++
    },
    chgsetDays(item, oldv, newv) {
      this.$set(this.record.config.library.loan[item.gestiune][item.tipUtilizator][item.regim], "days", newv)
      this.render++
    },
    chgsetItems(item, oldv, newv) {
      this.$set(this.record.config.library.loan[item.gestiune][item.tipUtilizator][item.regim], "items", newv)
      this.render++
    },
    generateToken(length) {
      //edit the token allowed characters
      var a = "abcdef1234567890".split("")
      var b = []
      for (var i = 0; i < length; i++) {
        var j = (Math.random() * (a.length - 1)).toFixed(0)
        b[i] = a[j]
      }
      return b.join("")
    },
    createURL(img, fil) {
      this.$set(this.dictionary, img, URL.createObjectURL(fil.target.files[0]))
    },
    fileChanged(e) {
      this.file = e.target.files[0]
      this.urlImg = URL.createObjectURL(this.file)
      this.fileName = this.file.name
    },
    clearFile() {
      this.fileName = ""
      this.file = null
    },
    createAndEdit(e, arr, field, ind) {
      //searchRelation(arr, field, ind, res, fld) {
      this.linkArray = arr
      this.linkField = field
      this.linkIndex = ind
      this.selectId = Date.now()
      this.$log("createAndEdit ", e)
      this.$refs.createEdit.showData(e, this.selectId)
    },
    checkTVA(e) {
      //this.$log("createAndEdit ", md5("ke"))
      if (e.length > 5) {
        const dat = new Date()
        axios.post("anaf_check_tva", [{ cui: e, data: dat.toJSON().split("T")[0] }]).then(response => {
          if (response.data.found.length > 0) {
            this.record.invoice.tva = response.data.found[0].scpTVA
          }
        })
      }
    },
    loadCUIData(e) {
      if (e.length > 5) {
        const dat = new Date()
        axios.post("anaf_check_tva", [{ cui: e, data: dat.toJSON().split("T")[0] }]).then(response => {
          if (response.data.found.length > 0) {
            this.record.invoice.tva = response.data.found[0].scpTVA
            this.record.name = response.data.found[0].denumire
            const adr = response.data.found[0].adresa.split(",")
            if (adr.length > 2) {
              this.record.address.address = response.data.found[0].adresa.replace(adr[0] + "," + adr[1] + ",", "")
            }
            this.record.address.locality = adr[1]
            this.record.address.country = "România"
            if (adr[0].substr(0, 3) == "JUD") {
              const jud = adr[0].substr(5).toLowerCase()
              this.record.address.county = adr[0].substr(5)
              this.dictionary.county.map(itm => {
                if (itm.value.toLowerCase() == jud) {
                  this.record.address.county = itm.value
                }
              })
            } else {
              this.record.address.county = "Bucureşti"
            }
          }
        })
      }
    },
    getdictionary(dic) {
      return this.$store.getters.dictionary(dic)
    },
    getDictionaryValues(dic) {
      //this.$log("getDictionaryValues(itm) ", dic)
      const dictionary = dic.split(".")
      if (dictionary[0] == "all") {
        //axios.get('app_dictionary?where={"value":"' + dictionary[1] + '"}').then(response => {
        this.$set(this.dictionary, dictionary[1], this.getdictionary("all_" + dictionary[1]))
        // this.dictionary[dictionary[1]] = response.data._items[0].content
        //})
      } else if (dictionary[0] == "system") {
        axios.get('app_system_dictionary?where={"value":"' + dictionary[1] + '"}').then(response => {
          this.$set(this.dictionary, dictionary[1], response.data._items[0].content)
          // this.dictionary[dictionary[1]] = response.data._items[0].content
        })
      }
    },
    addSubItem(e, ind) {
      if (ind == null) {
        this.resolvePath(e, this.record).push(JSON.parse(JSON.stringify(this.resolvePath(e, this.recordView)[0])))
      } else {
        this.resolvePath(e, this.record).splice(
          ind,
          0,
          JSON.parse(JSON.stringify(this.resolvePath(e, this.recordView)[0]))
        )
      }
    },
    delSubItem(e, ind) {
      this.resolvePath(e, this.record).splice(ind, 1)
      if (this.resolvePath(e, this.record).length == 0) {
        this.addSubItem(e)
      }
    },
    searchRelationMarc(field, subfield, ind, prefix, ctg) {
      this.linkField = field
      this.linkSubfield = subfield
      this.linkIndex = ind
      //this.$log("srcrel ", this.schemaContent[fields.field + subfield])
      this.searchId = Date.now()
      let where = ""
      let vTable = ""
      if (ctg) {
        where = '"ctg":' + '"' + ctg + '"'
        const ctgview = this.$store.getters.appViewTableCollectionCategory("marc_" + prefix, ctg)
        if (ctgview.length > 0) {
          vTable = ctgview[0]._id
        }
      }
      this.$refs.searchLink.open("ebib_marc_" + prefix, null, this.searchId, {
        where: where,
        viewTable: vTable,
        ctg: ctg
      })
      // EventBus.$emit("search-link", {
      //   resource: "ebib_marc_" + prefix,
      //   field: null,
      //   id: this.searchId,
      //   config: { where: where, viewTable: vTable, ctg: ctg }
      // })
      //this.$refs.searchLink.open(res, fld, this.searchId)
    },
    searchRelation(arr, field, ind, res, fld) {
      this.linkArray = arr
      this.linkField = field
      this.linkIndex = ind
      this.searchId = Date.now()
      this.$refs.searchLink.open(res, fld, this.searchId)
    },
    setLink(field, subfield, ind, prefix, ctg, e) {
      this.$log("eeee ", e)
    },
    setLinkValue(e) {
      if (this.linkIndex != null) {
        if (this.linkField != null) {
          //this.linkArray[this.linkIndex][this.linkField] = e
          this.$set(this.linkArray[this.linkIndex], this.linkField, e)
        } else {
          if (typeof e === "object") {
            e.iiid = this.idGlobal++
          }
          if (this.linkIndex == -1) {
            this.linkArray.push(e)
          } else {
            //this.linkArray[this.linkIndex] = e
            this.$set(this.linkArray, this.linkIndex, e)
          }
        }
      } else {
        //this.linkArray[this.linkField] = e
        this.$set(this.linkArray, this.linkField, e)
        this.render = Date.now()
      }
    },
    resolvePath(path, obj) {
      return path.split(".").reduce(function(prev, curr) {
        return prev ? prev[curr] : null
      }, obj || self)
    }
  }
}
</script>
